import React, { useState } from "react"
import Layout from "../components/layout"
import config from "../utils/siteConfig"
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import Button from "../components/Atoms/Button/Button"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight, faDatabase, faPlay, faIndustry, faCheckCircle } from "@fortawesome/free-solid-svg-icons"

export default function Product({ data }) {
    debugger;
    const page = data.allContentfulZProductFeature.edges[0].node

    const Bold = ({ children }) => <span className="bold3">{children}</span>
    //const Text = ({ children }) => <p className="align-center">{children}</p>;

    const options = {
        renderNode: {
            [BLOCKS.HEADING_1]: (node, children) => (
                <h1 className="heading1">{children}</h1>
            ),
            [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="heading2">{children}</h2>
            ),
            [BLOCKS.HEADING_3]: (node, children) => (
                <h3 className="heading3">{children}</h3>
            ),
            [BLOCKS.HEADING_4]: (node, children) => (
                <h4 className="bold">{children}</h4>
            ),
            [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
                <img src={`https:${node.data.target.fields.file["en-US"].url}`} />
            ),
            [BLOCKS.PARAGRAPH]: (node, children) => (
                <p className="">{children}</p>
            ),
            [BLOCKS.UL_LIST]: (node, children) => {
                return (
                    <ul
                        style={{
                            listStyle: 'disc',
                            lineHeight: '35px',
                            marginLeft: '1rem',
                        }}
                    >
                        {children.map((item) => (
                            <li key={item.key}>{item.props.children[0].props.children[0]}</li>
                        ))}
                    </ul>
                );
            },
            //[BLOCKS.UL_LIST]: (node, children) => (
            //    <span className="d-inline-block bulletSpan">{children}</span>
            //),

        },
        renderMark: {
            [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        },
        renderText: (text) =>
            text
                .replace(/\u2028/g, "")
                .split("\n")
                .flatMap((text, i) => [i > 0 && <br />, text])
    }

    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    };

    const {
        id,
        metaData,
        pfFBtn,
        pfFHd,
        pfFTxt,
        pfHHd,
        pfHHd2,
        pfHTxt,
        pfHBtn,
        pfHBtn2,
        pfS2Hd2,
        pfS3Hd2,
        pfS4Hd2,
        pfS5Hd2,
        pfS2Hd,
        pfS2Txt,
        pfS3Hd,
        pfS3Txt,
        pfS4Hd,
        pfS4Txt,
        pfS5Hd,
        pfS5Txt,
        pfS6Hd,
        pfS6Hd2,
        pfS6Txt,
        pfS6bHd,
        pfS6bHd2,
        pfS6bTxt,
        pfAo2Image,
        pfAo2Txt,
        pfAo2Txt2,
        pfAo2Txt3,
        pfAo2Txt4,
        pfAoImage,
        pfAoRtxt,
        pfAoTxt,
        pfAoTxt2,
        pfAoTxt3,
        pfAoTxt4,
        pfHImage,
        pfS2Image,
        pfS3Image,
        pfS4Image,
        pfS5Image,
        pfS6Image,
        pfS6bImage,
        pfAo2Rtxt,
    } = page


    return (
        <Layout
            IsHeaderImageDark={false}
            navColour={"white"}>
            <head>
                <title>{metaData.title}</title>
                <meta charset="utf-8" />
                <meta name="description" content={metaData.description} />
                <meta name="keywords" content={metaData.keywords.keywords} />
                <meta name="robots" content="noindex" />
                <link rel="alternate" hreflang="de" href="https://wunderloop.io/de/feature" />
        <link rel="alternate" hreflang="en" href="https://wunderloop.io/en/feature" />
            </head>

            {/*Header section*/}
            <section className="bg-white">
                <div className="head-container2">
                    <div className="row align-items-center">
                        <div className="col-lg-8">
                            <div className="bg-white">
                                <div className="row">
                                    <h1 className="align-top h1-small h1-purple">
                                        {pfHHd2}
                                    </h1>
                                </div>
                                <div className="row pt-5 pr-4">
                                    <h1 className="h1-largeMedium2 pt-3 h1-darkBlue">
                                        {pfHHd}
                                    </h1>
                                </div>
                                <div className="row pt-4 pr-4">
                                    <h2 className="h2-large text-darkgray">
                                        {pfHTxt.pfHTxt}
                                    </h2>
                                </div>
                                <div class="row pt-4">
                                    <div class="text-center">
                                        <Button
                                            className='btns'
                                            buttonStyle='btn--outlinePurple'
                                            buttonSize='btn--large'
                                            href={config.navigationURL_register}
                                        >
                                            {pfHBtn}{" "}
                                            <FontAwesomeIcon className="" size="1x" icon={faChevronRight} className="faChevronRight" />
                                        </Button>
                                    </div>
                                    <div class="text-center">
                                        <Button
                                            className='btns'
                                            buttonStyle='btn--primaryPurple'
                                            buttonSize='btn--large'
                                            href={config.navigationURL_contactSales}
                                        >
                                            {pfHBtn2}{" "}
                                            <FontAwesomeIcon className="" size="1x" icon={faChevronRight} className="faChevronRight" />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex__wrapper col-lg-4 bg-white pt-5">
                            <img src={pfHImage.file.url} className="img-fluid" alt={pfHImage.title} />
                        </div>
                    </div>
                </div>
            </section>

            {/*Section 1*/}
            <section className="bg-white">
                <div className="sectionFit-container bg-white">
                    <div className="row">
                        <div className="col-lg-6 pb-5">
                            <div className="pl-5">
                                <img src={pfS2Image.file.url} className="img-fluid" alt={pfS2Image.title} />
                            </div>
                        </div>
                        <div className="col-lg-6 pb-5">
                            <div className="row">
                                <h1 className="align-top h1-small h1-purple">
                                    {pfS2Hd2}
                                </h1>
                            </div>
                            <div className="row pt-4">
                                <h1 className="align-top h1-medium h1-darkBlue">
                                    {pfS2Hd}
                                </h1>
                            </div>
                            <div className="row pr-5 pt-4">
                                <h2 className="h2-large text-darkgray pb-3 pr-5">
                                    {pfS2Txt.pfS2Txt}
                                </h2>
                            </div>
                            <div className="row pt-3">
                                <div class="text-center">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--outlinePurple'
                                        buttonSize='btn--large'
                                        href={config.navigationURL_contactSales}
                                    >
                                        <FormattedMessage id="explore_the_toolsets" />{" "}
                                        <FontAwesomeIcon className="" size="1x" icon={faChevronRight} className="faChevronRight" />
                                    </Button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            {/*Section 2*/}
            <section className="bg-lightgray">
                <div className="sectionFit-container bg-lightgray">
                    <div className="row align-items-center">

                        <div className="col-lg-6 pb-5">
                            <div className="row text-white pb-5">
                                <h1 className="align-top h1-medium h1-darkBlue">
                                    {documentToReactComponents(pfAoRtxt.json, options)}
                                </h1>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 pl-0 pt-3 pr-5 align-items-center">
                                    <div className="row pl-3">
                                        <FontAwesomeIcon icon={faCheckCircle} className="mt-1 mr-4" />
                                        <h2 className="h2-large text-darkgray pt-0">
                                            {pfAoTxt}
                                        </h2>
                                    </div>
                                </div>
                                <div className="col-lg-12 pl-0 pt-3 pr-5 align-items-center">
                                    <div className="row pl-3">
                                        <FontAwesomeIcon icon={faCheckCircle} className="mt-1 mr-4" />
                                        <h2 className="h2-large text-darkgray pt-0">
                                            {pfAoTxt2}
                                        </h2>
                                    </div>
                                </div>
                                <div className="col-lg-12 pl-0 pt-3 pr-5 align-items-center">
                                    <div className="row pl-3">
                                        <FontAwesomeIcon icon={faCheckCircle} className="mt-1 mr-4" />
                                        <h2 className="h2-large text-darkgray pt-0">
                                            {pfAoTxt3}
                                        </h2>
                                    </div>
                                </div>
                                <div className="col-lg-12 pl-0 pt-3 pr-5 align-items-center">
                                    <div className="row pl-3">
                                        <FontAwesomeIcon icon={faCheckCircle} className="mt-1 mr-4" />
                                        <h2 className="h2-large text-darkgray pt-0">
                                            {pfAoTxt4}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-4">
                                <div class="text-center">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--primaryPurple'
                                        buttonSize='btn--large2'
                                        href={config.navigationURL_contactSales}
                                    >
                                        <FormattedMessage id="learn_more" />{" "}
                                        <FontAwesomeIcon className="" size="1x" icon={faChevronRight} className="faChevronRight" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 pb-5 pl-4">
                            <img src={pfAoImage.file.url} className="img-fluid" alt={pfAoImage.title} />
                        </div>
                    </div>
                </div>
            </section>

            {/*Section 3*/}
            <section className="bg-lightgray">
                <div className="sectionFit-container bg-lightgray">

                    <div className="row align-items-center">
                        <div className="col-lg-6 pb-5 pr-5">
                            <img src={pfAo2Image.file.url} className="img-fluid" alt={pfAo2Image.title} />
                        </div>
                        <div className="col-lg-6 pb-5">
                        <div className="row text-white pb-5">
                                <h1 className="align-top h1-medium h1-darkBlue">
                                    {documentToReactComponents(pfAo2Rtxt.json, options)}
                                </h1>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 pl-0 pt-3 pr-5 align-items-center">
                                    <div className="row pl-3">
                                        <FontAwesomeIcon icon={faCheckCircle} className="mt-1 mr-4" />
                                        <h2 className="h2-large text-darkgray pt-0">
                                            {pfAo2Txt}
                                        </h2>
                                    </div>
                                </div>
                                <div className="col-lg-12 pl-0 pt-3 pr-5 align-items-center">
                                    <div className="row pl-3">
                                        <FontAwesomeIcon icon={faCheckCircle} className="mt-1 mr-4" />
                                        <h2 className="h2-large text-darkgray pt-0">
                                            {pfAo2Txt2}
                                        </h2>
                                    </div>
                                </div>
                                <div className="col-lg-12 pl-0 pt-3 pr-5 align-items-center">
                                    <div className="row pl-3">
                                        <FontAwesomeIcon icon={faCheckCircle} className="mt-1 mr-4" />
                                        <h2 className="h2-large text-darkgray pt-0">
                                            {pfAo2Txt3}
                                        </h2>
                                    </div>
                                </div>
                                <div className="col-lg-12 pl-0 pt-3 pr-5 align-items-center">
                                    <div className="row pl-3">
                                        <FontAwesomeIcon icon={faCheckCircle} className="mt-1 mr-4" />
                                        <h2 className="h2-large text-darkgray pt-0">
                                            {pfAo2Txt4}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-4">
                                <div class="text-center">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--primaryPurple'
                                        buttonSize='btn--large2'
                                        href={config.navigationURL_contactSales}
                                    >
                                        <FormattedMessage id="learn_more" />{" "}
                                        <FontAwesomeIcon className="" size="1x" icon={faChevronRight} className="faChevronRight" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*Section 3*/}
            <section className="bg-white">
                <div className="sectionFit-container bg-white">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pb-5">
                            <div className="row">
                                <h1 className="align-top h1-small h1-purple">
                                    {pfS3Hd2}
                                </h1>
                            </div>
                            <div className="row pt-4">
                                <h1 className="align-top h1-medium h1-darkBlue">
                                    {pfS3Hd}
                                </h1>
                            </div>
                            <div className="row pr-5 pt-4">
                                <h2 className="h2-large text-darkgray pb-3 pr-5">
                                    {pfS3Txt.pfS3Txt}
                                </h2>
                            </div>
                            <div className="row pt-3">
                                <div class="text-center">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--outlinePurple'
                                        buttonSize='btn--large'
                                        href={config.navigationURL_solution_deskbooking}
                                    >
                                        <FormattedMessage id="learn_more" />{" "}
                                        <FontAwesomeIcon className="" size="1x" icon={faChevronRight} className="faChevronRight" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 pb-5">
                            <img src={pfS3Image.file.url} className="img-fluid" alt={pfS3Image.title} />

                        </div>
                    </div>
                </div>
            </section>

            {/*Section 4*/}

            <section className="bg-white">
                <div className="sectionFit-container bg-white">
                    <div className="row">
                        <div className="col-lg-6 pb-5">
                            <div className="pr-5">
                                <img src={pfS4Image.file.url} className="img-fluid" alt={pfS4Image.title} />
                            </div>
                        </div>
                        <div className="col-lg-6 pb-5">
                            <div className="row">
                                <h1 className="align-top h1-small h1-purple">
                                    {pfS4Hd2}
                                </h1>
                            </div>
                            <div className="row pt-4">
                                <h1 className="align-top h1-medium h1-darkBlue">
                                    {pfS4Hd}
                                </h1>
                            </div>
                            <div className="row pr-5 pt-4">
                                <h2 className="h2-large text-darkgray pb-3 pr-5">
                                    {pfS4Txt.pfS4Txt}
                                </h2>
                            </div>
                            <div className="row pt-3">
                                <div class="text-center">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--outlinePurple'
                                        buttonSize='btn--large'
                                        href={config.navigationURL_solution_workexperience}
                                    >
                                        <FormattedMessage id="learn_more" />{" "}
                                        <FontAwesomeIcon className="" size="1x" icon={faChevronRight} className="faChevronRight" />
                                    </Button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            {/*Section 5*/}
            <section className="bg-lightgray">
                <div className="sectionFit-container bg-lightgray">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pb-5">
                            <div className="row">
                                <h1 className="align-top h1-small h1-purple">
                                    {pfS5Hd2}
                                </h1>
                            </div>
                            <div className="row pt-4">
                                <h1 className="align-top h1-medium h1-darkBlue">
                                    {pfS5Hd}
                                </h1>
                            </div>
                            <div className="row pr-5 pt-4">
                                <h2 className="h2-large text-darkgray pb-3 pr-5">
                                    {pfS5Txt.pfS5Txt}
                                </h2>
                            </div>
                            <div className="row pt-3">
                                <div class="text-center">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--outlinePurple'
                                        buttonSize='btn--large'
                                        href={config.navigationURL_sustainability}
                                    >
                                        <FormattedMessage id="learn_more" />{" "}
                                        <FontAwesomeIcon className="" size="1x" icon={faChevronRight} className="faChevronRight" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 pb-5">
                            <div className="pt-5">
                                <img src={pfS5Image.file.url} className="img-fluid" alt={pfS5Image.title} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/*Section 7*/}
            <section className="bg-white">
                <div className="sectionFit-container bg-white">
                    <div className="row">
                        <div className="col-lg-6 pb-5 pr-5">
                            <img src={pfS6Image.file.url} className="img-fluid" alt={pfS6Image.title} />

                        </div>
                        <div className="col-lg-6 pb-5">
                            <div className="row">
                                <h1 className="align-top h1-small h1-purple">
                                    {pfS6Hd2}
                                </h1>
                            </div>
                            <div className="row pt-4">
                                <h1 className="align-top h1-medium text-darkblue">
                                    {pfS6Hd}
                                </h1>
                            </div>
                            <div className="row pr-5 pt-4">
                                <h2 className="h2-large text-darkgray pb-3 pr-5">
                                    {pfS6Txt.pfS6Txt}
                                </h2>
                            </div>
                            <div className="row pt-3">
                                <div class="text-center">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--outlinePurple'
                                        buttonSize='btn--large'
                                        href={config.navigationURL_mobileapp}
                                    >
                                        {pfHBtn}{" "}
                                        <FontAwesomeIcon className="" size="1x" icon={faChevronRight} className="faChevronRight" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/*Section 6b*/}
            <section className="bg-lightgray">
                <div className="sectionFit-container bg-lightgray">
                    <div className="row">
                        <div className="col-lg-6 pb-5">
                            <div className="row">
                                <h1 className="align-top h1-small h1-purple">
                                    {pfS6bHd2}
                                </h1>
                            </div>
                            <div className="row pt-4">
                                <h1 className="align-top h1-medium h1-darkBlue">
                                    {pfS6bHd}
                                </h1>
                            </div>
                            <div className="row pr-5 pt-4">
                                <h2 className="h2-large text-darkgray pb-3 pr-5">
                                    {pfS6bTxt.pfS6bTxt}
                                </h2>
                            </div>
                        </div>
                        <div className="col-lg-6 pb-5">
                            <div className="pt-5">
                                <img src={pfS6bImage.file.url} className="img-fluid" alt={pfS6bImage.title} />

                            </div>
                        </div>
                    </div>
                </div>
            </section>



            {/*Footer Section*/}
            <section className="bg-darkblue">
                <div className="sectionFit-container">
                    <div className="coloum">
                        <div className="mb-4 px-4 pt-4">
                            <div className="text-center">
                                <h1 className="h1-mediumSmall text-white pt-4">
                                    {pfFHd}
                                </h1>
                            </div>
                            <div className="text-center">
                                <h2 className="h2-large h2-lightgray pt-4">
                                    {pfFTxt}
                                </h2>
                            </div>
                            <div class="h-100 text-center pt-4">
                                <Button
                                    className='btns'
                                    buttonStyle='btn--outlineWhite'
                                    buttonSize='btn--large'
                                    href={config.navigationURL_contactUs}
                                >
                                    {pfFBtn}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </Layout >
    );
}


export const query = graphql`
query($locale: String!) {
    allContentfulZProductFeature(
        filter: {
        node_locale: { eq: $locale }
    }) {
        edges {
            node {
                id
                metaData {
                    slug
                    title
                    description
                    keywords {
                        keywords
                    }
                }
                pfFBtn
                pfHImage{
                    description
                    title
                    file {
                        fileName
                        url
                    }
                }
                pfS2Image{
                    description
                    title
                    file {
                        fileName
                        url
                    }
                }
                pfS4Image{
                    description
                    title
                    file {
                        fileName
                        url
                    }
                }
                pfS5Image{
                    description
                    title
                    file {
                        fileName
                        url
                    }
                }
                pfFHd
                pfFTxt
                pfHHd
                pfHHd2
                pfHTxt {
                    pfHTxt
                }
                pfHBtn
                pfHBtn2
                pfS2Hd
                pfS2Txt {
                    pfS2Txt
                }
                pfS3Hd
                pfS3Txt {
                    pfS3Txt
                }
                pfS4Hd
                pfS4Txt {
                    pfS4Txt
                }
                pfS5Hd
                pfS5Txt {
                    pfS5Txt
                }
                pfS6Hd
                pfS6Hd2
                pfS6Txt {
                    pfS6Txt
                }
                pfS6bHd
                pfS6bHd2
                pfS6bTxt {
                    pfS6bTxt
                }
               
                pfS2Hd2
                pfS3Hd2
                pfS4Hd2
                pfS5Hd2
                pfAo2Image {
                    description
                    file {
                      url
                      fileName
                    }
                    title
                  }
                  pfAo2Rtxt {
                    json
                  }
                  pfAo2Txt
                  pfAo2Txt2
                  pfAo2Txt3
                  pfAo2Txt4
                  pfAoImage {
                    description
                    file {
                      fileName
                      url
                    }
                    title
                  }
                  pfAoRtxt {
                    json
                  }
                  pfAoTxt
                  pfAoTxt2
                  pfAoTxt3
                  pfAoTxt4
                  pfHImage {
                    description
                    file {
                      fileName
                      url
                    }
                    title
                  }
                  pfS2Image {
                    description
                    file {
                      fileName
                      url
                    }
                    title
                  }
                  pfS3Image {
                    description
                    file {
                      url
                      fileName
                    }
                    title
                  }
                  pfS4Image {
                    description
                    file {
                      fileName
                      url
                    }
                    title
                  }
                  pfS5Image {
                    description
                    file {
                      fileName
                      url
                    }
                    title
                  }
                  pfS6Image {
                    file {
                      url
                      fileName
                    }
                    description
                    title
                  }
                  pfS6bImage {
                    description
                    file {
                      fileName
                      url
                    }
                    title
                  }
                  pfAo2Rtxt {
                    json
                  }
            }
        }
    }
}
`
